<template>
  <v-dialog
    v-model="dialog"
    fullscreen
    transition="dialog-transition"
  >
    <v-card v-if="dialog">
      <v-carousel
        hide-delimiters
        :show-arrows="false"
        cycle
        :interval="intervalInSeconds * 1000"
        height="100vh"
      >
        <v-carousel-item
          v-for="(item, i) in items"
          :key="i"
          :src="item.photo | mImage"
        />
      </v-carousel>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'MScreensaver',
  props: {
    seconds: {
      type: [String, Number],
      default: () => 5
    },
    intervalInSeconds: {
      type: [String, Number],
      default: () => 5
    },
    screensaverActiveTime: {
      type: [String, Number],
      default: () => 300
    },
    items: {
      type: Array,
      required: true
    }
  },
  data: () => ({
    dialog: false,
    timer: null,
    restart: null
  }),
  mounted() {
    document.addEventListener('touchmove', this.resetIdleTimer)
    document.addEventListener('mousemove', this.resetIdleTimer)
    this.resetIdleTimer()
  },
  beforeDestroy() {
    clearTimeout(this.timer)
    document.removeEventListener('touchmove', this.resetIdleTimer)
    document.removeEventListener('mousemove', this.resetIdleTimer)
  },
  methods: {
    showScreensaver() {
      this.dialog = true
      if (this.screensaverActiveTime > 0) {
        this.restart = setTimeout(this.resetIdleTimer, this.screensaverActiveTime * 1000)
      }
    },
    resetIdleTimer() {
      clearTimeout(this.timer)
      clearTimeout(this.restart)
      this.dialog = false
      this.timer = setTimeout(this.showScreensaver, this.seconds * 1000)
    }
  }
}
</script>
